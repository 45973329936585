import { useState } from 'react'
import { Button, DateRangePicker } from '@travelpass/design-system'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import type { DateInterval } from 'react-day-picker'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useLucencyNumber } from 'src/config/analytics/useLucencyNumber'
import { experiencesResultsPath } from 'src/constants'

import type { DatesType, GeocoderType } from 'src/constants/user'
import { initialDates, initialGeocoder } from 'src/constants/user'
import { constructArguments } from './searchExperiencesUtils'
import type { GeocoderProps } from '../Geocoder'
import { Geocoder } from '../Geocoder'

interface SearchExperiencesSubmitArguments {
  dates: DatesType
  geocoder: GeocoderType
}

interface SearchExperiencesProps {
  checkTypes?: GeocoderProps['checkTypes']
  dates?: DatesType
  focusOnGeocoderInput?: GeocoderProps['focusOnInput']
  geocoder?: GeocoderType
  onChangeDates?: (arg0: DatesType) => void
  onChangeGeocoder?: (arg0: GeocoderType) => void
  onClose?: () => void
}

const maxDate = dayjs().add(1, 'year').toDate()

const SearchExperiences = ({
  checkTypes = false,
  focusOnGeocoderInput = true,
  dates = initialDates,
  geocoder = initialGeocoder,
  onChangeDates = () => {},
  onChangeGeocoder = () => {},
  onClose = () => {},
}: SearchExperiencesProps) => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { updateLucency } = useLucencyNumber()

  const [isInvalid, setIsInvalid] = useState(false)

  const onGeocoderChange = (updatedGeocoder: GeocoderType) => {
    if (updatedGeocoder !== initialGeocoder) {
      setIsInvalid(false)
    }
    onChangeGeocoder(updatedGeocoder)
  }

  const onDatesChange = (updatedDates: DatesType) => onChangeDates(updatedDates)

  const isResultsRoute = location.pathname === experiencesResultsPath
  const willUpdate = isResultsRoute

  const onDateSelect = (range: { to: Dayjs; from: Dayjs }) => {
    // track check-in and check-out dates to lucency
    updateLucency({
      checkin: range.from,
      checkout: range?.to,
    })
    const updatedDates: DatesType = [range.from, range?.to]
    onDatesChange(updatedDates)
  }

  const onButtonClick = () => {
    if (geocoder === initialGeocoder) {
      setIsInvalid(true)
    } else {
      const constructedArguments = constructArguments({
        dates,
        geocoder,
        searchParams,
        willUpdate,
      })
      navigate(constructedArguments)
      onClose()
    }
  }

  const oneMonthIntervalMatcher: DateInterval = {
    after: dates[0].add(30, 'day').toDate(),
    before: dates[0].subtract(30, 'day').toDate(),
  }

  return (
    <div className='color-grey900 grid grid-flow-dense grid-cols-1 gap-2 lg:gap-x-5 lg:gap-y-2'>
      <div className='lg:col-span-3'>
        <Geocoder
          checkTypes={checkTypes}
          focusOnInput={focusOnGeocoderInput}
          geocoder={geocoder}
          helperText={!!isInvalid && 'Please select a destination.'}
          isInvalid={isInvalid}
          label='Destination'
          onResult={onGeocoderChange}
        />
      </div>
      <div className='lg:col-span-2'>
        <DateRangePicker
          canSelectSameDay
          disabled={!dates[1] && oneMonthIntervalMatcher}
          label='Travel Dates'
          numberOfMonths={1}
          selected={{
            from: dates[0],
            to: dates[1],
          }}
          toDate={maxDate}
          onSelect={onDateSelect}
        />
      </div>
      <div className='flex flex-col items-center pt-5 lg:col-span-3'>
        <Button
          label='Search Experiences'
          size='large'
          startIcon='search'
          onClick={onButtonClick}
        />
      </div>
    </div>
  )
}

export { SearchExperiences }
export type { SearchExperiencesProps }
