import classNames from 'classnames'
import {
  ScrollMenu,
  type Props as ScrollMenuProps,
  type VisibilityContext,
} from 'react-horizontal-scrolling-menu'
import { useDrag } from './useDrag'

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>

export const Scroller = ({
  children,
  scrollContainerClassName,
  hideScrollBar = false,
  allowTouch = false,
  ...props
}: ScrollMenuProps & { hideScrollBar?: boolean; allowTouch?: boolean }) => {
  const hideScrollbar =
    (!!props?.LeftArrow && !!props?.RightArrow) || hideScrollBar

  const { dragStart, dragStop, dragMove, dragging } = useDrag()
  const handleDrag =
    ({ scrollContainer }: scrollVisibilityApiType) =>
    (ev: React.MouseEvent) =>
      dragMove(ev, posDiff => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff
        }
      })

  return (
    <div
      {...(allowTouch && {
        onMouseLeave: () => dragStop(),
      })}
    >
      <ScrollMenu
        itemClassName={classNames({
          'pointer-events-none': allowTouch && dragging,
        })}
        scrollContainerClassName={classNames(scrollContainerClassName, {
          'hide-scrollbar': hideScrollbar,
        })}
        wrapperClassName='all-[.react-horizontal-scrolling-menu--inner-wrapper]:items-center'
        {...(allowTouch
          ? {
              onMouseDown: () => dragStart,
              onMouseMove: handleDrag,
              onMouseUp: () => dragStop,
              onWheel: onWheel,
            }
          : {})}
        {...props}
      >
        {children}
      </ScrollMenu>
    </div>
  )
}

function onWheel(apiObj: scrollVisibilityApiType, ev: React.WheelEvent): void {
  const isTouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15

  if (isTouchpad) {
    ev.stopPropagation()
    return
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext()
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev()
  }
}
