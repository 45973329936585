import { Link, Modal } from '@travelpass/design-system'
import {
  onPhoneNumberClick,
  useLucencyNumber,
} from 'src/config/analytics/useLucencyNumber'

interface LucencySupportModalProps {
  onClose(): void
}

export const LucencySupportModal = ({ onClose }: LucencySupportModalProps) => {
  const { lucencyNumber } = useLucencyNumber()

  return (
    <Modal size='medium' title='Support' onDismiss={onClose}>
      <div className='type-body-1 c-grey-800'>
        <div>
          For help from our dedicated customer support team, please call{' '}
          <Link
            label={lucencyNumber}
            onClick={() => {
              onPhoneNumberClick(lucencyNumber)
              window.location.href = `tel:${lucencyNumber}`
            }}
          />
          . They are available Monday through Friday between 9:00am and 5:00pm
          MT.
        </div>
      </div>
    </Modal>
  )
}
