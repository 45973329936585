import { useEffect, useMemo, useState } from 'react'
import { NetworkStatus, useQuery } from '@apollo/client'
import cloneDeep from 'lodash.clonedeep'
import merge from 'lodash.merge'
import { useSearchParams } from 'react-router-dom'
import { gql } from 'src/__generated__'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import {
  getArrivalDate,
  getDepartureDate,
  getGeocoderLatitude,
  getGeocoderLongitude,
  logError,
} from 'src/utils'
import { ExperienceSearchParam } from '../../experienceResultsConstants'
import type { ResultsRefetchArguments } from '../utils'
import { constructExperienceResultsArguments } from '../utils'

/**
 * @desc attempting to destructure the location string to get the parts from Google Places location string
 */
export const destructureLocation = string => {
  const locationParts = string?.split(',').map(part => part?.trim())
  switch (locationParts?.length) {
    case 1: {
      const [city] = locationParts
      return {
        city,
      }
    }
    case 2: {
      const [city, state] = locationParts
      return {
        city,
        state,
      }
    }
    case 3: {
      const [city, state, country] = locationParts
      return {
        city,
        state,
        country,
      }
    }
    default: {
      if (!locationParts?.length) {
        return {
          city: null,
          state: null,
          country: null,
        }
      } else {
        const [country, state, city, ..._rest] = locationParts?.reverse()
        return {
          city,
          state,
          country,
        }
      }
    }
  }
}

const standardExperiencesQuery = gql(`
query StandardExperiencesQueryInResults($searchExperiencesArgs: SearchExperiencesArgs!, $after: String, $first: Int, $arrival: Date!, $departure: Date) {
  searchExperiences(searchExperiencesArgs: $searchExperiencesArgs, after: $after, first: $first) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      cursor
      node {
        id
        availabilitySchedule {
          summary(arrival: $arrival, departure: $departure) {
            fromPrice {
              amount
            }
            fromPriceBeforeDiscount {
              amount
            }
          }
        }
        cancellationPolicy {
          type
        }
        images {
          size360x240
        }
        itinerary {
          ... on ExperienceStandardItinerary {
            duration {
              durationRange
            }
            itineraryType
          }
          ... on ExperienceActivityItinerary {
            duration {
              durationRange
            }
            itineraryType
          }
          ... on ExperienceMultidayItinerary {
            itineraryType
          }
          ... on ExperienceHopOnHopOffItinerary {
            duration {
              durationRange
            }
            itineraryType
          }
          ... on ExperienceUnstructuredItinerary {
            duration {
              durationRange
            }
            itineraryType
          }
        }
        pricingInfo {
          unitType
        }
        reviews {
          combinedAverageRating
          totalReviews
        }
        title
        isFavorited
      }
    }
  }
}
`)

const useExperienceResultsQuery = () => {
  const [hasMoreResults, setHasMoreResults] = useState(true)
  const [searchParams] = useSearchParams()

  const constructedResultsArguments = useMemo(
    () =>
      constructExperienceResultsArguments({
        searchParams,
      }),
    [searchParams]
  )

  useEffect(() => {
    const arrival = searchParams.get(ExperienceSearchParam.arrival)
    const departure = searchParams.get(ExperienceSearchParam.departure)
    const latitude = searchParams.get(ExperienceSearchParam.latitude)
    const longitude = searchParams.get(ExperienceSearchParam.longitude)

    onRefetch({
      arrival: getArrivalDate({ date: arrival }),
      departure: getDepartureDate({ date: departure }),
      latitude: getGeocoderLatitude(latitude),
      longitude: getGeocoderLongitude(longitude),
    })
  }, [searchParams])

  const {
    data,
    error: getExpResultsError,
    loading,
    networkStatus,
    fetchMore: onGetMoreResultsQuery,
    refetch,
  } = useQuery(standardExperiencesQuery, {
    notifyOnNetworkStatusChange: true,
    variables: constructedResultsArguments,
    onCompleted: data => {
      const experiences = data?.searchExperiences?.edges?.map(e => e.node.id)

      pushDataToDataLayer('experienceSearchResults', {
        experiences, // assumption: we should not need to send the whole result payload to GTM
        searchArgs: constructedResultsArguments,
        location: destructureLocation(searchParams.get('location')),
      })
    },
  })

  const isRefetching = networkStatus === NetworkStatus.refetch
  const isMoreResultsLoading = networkStatus === NetworkStatus.fetchMore
  const isLoading = (!isMoreResultsLoading && loading) || isRefetching

  const { edges, pageInfo } = data?.searchExperiences ?? {}
  const resultsData = edges ?? []

  const onRefetch = async (
    updatedResultsArguments: ResultsRefetchArguments
  ) => {
    const constructedResultsArguments = constructExperienceResultsArguments({
      searchParams,
    })
    if (updatedResultsArguments?.filters) {
      delete constructedResultsArguments.searchExperiencesArgs.filters
    }

    const mergedResultsArguments = merge(constructedResultsArguments, {
      searchExperiencesArgs: {
        ...updatedResultsArguments,
      },
    })

    await refetch(mergedResultsArguments)
  }

  const onGetMoreResults = async () => {
    if (hasMoreResults) {
      await onGetMoreResultsQuery({
        variables: {
          ...constructedResultsArguments,
          after: pageInfo?.endCursor,
        },
        updateQuery: (previousResults, { fetchMoreResult }) => {
          const updatedResults = cloneDeep(fetchMoreResult)
          const { edges: previousResultsData } =
            previousResults?.searchExperiences ?? {}
          const { pageInfo, edges: currentResultsData } =
            fetchMoreResult?.searchExperiences ?? {}
          setHasMoreResults(pageInfo.hasNextPage)

          const updatedResultsData =
            previousResultsData.concat(currentResultsData)

          updatedResults.searchExperiences.edges = updatedResultsData
          return updatedResults
        },
      }).catch(error => logError(error))
    } else {
      setHasMoreResults(false)
    }
  }

  useEffect(() => {
    if (pageInfo?.hasNextPage) {
      setHasMoreResults(true)
    } else {
      setHasMoreResults(false)
    }
  }, [pageInfo])

  return {
    resultsData,
    hasError: getExpResultsError,
    hasMoreResults,
    isLoading,
    loading,
    isMoreResultsLoading,
    onGetMoreResults,
    onRefetch,
  }
}
