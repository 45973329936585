const env = import.meta.env.VITE_ENVIRONMENT

enum Environment {
  INT = 'int',
  STG = 'stg',
  PROD = 'prod',
}

const isIntEnv = env === Environment.INT
const isStgEnv = env === Environment.STG
const isProdEnv = env === Environment.PROD

export { env, Environment, isProdEnv }
