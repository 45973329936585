import isEmpty from 'lodash.isempty'
import type {
  LodgingFee,
  LodgingContentPolicy,
} from 'src/__generated__/graphql'
import type { HotelRatePolicies } from 'src/common/components'

type HotelCancellationPolicies = string | null

type HotelCancellationShortText = string | null

type HotelCheckIn = string | null

type HotelCheckInInstruction = string | null

type HotelSpecialCheckInInstruction = string | null

interface HotelFee {
  text: string | null
  type: string | null
}

interface HotelPolicy {
  text: string | null
  title?: string | null
}

interface HotelRatePolicy {
  description: string | null
  type: string | null
}

const additionalCancellationPolicies =
  "Each room in this reservation is subject to the hotel's cancellation policy. Dates and time in the policy refer to the property's local time"

const constructCleanItem = (item: string = ''): string =>
  item
    ?.split('</li>')
    ?.map(itemSplit => {
      const itemCleaned = itemSplit
        .replace(/(<([^>]+)>)/gi, '')
        .trim()
        .split('')

      if (itemCleaned.length > 0) {
        const itemReady = itemCleaned?.join('')

        return itemCleaned.includes('.') ? itemReady : itemReady + '.'
      }

      return ''
    })
    .join(' ')

const constructCleanItems = (items: string[] = []): string[] =>
  items?.map(item => constructCleanItem(item))

const constructCancellationPolicies = (
  cancellationPolicies: HotelCancellationPolicies
): string[] => {
  const updatedCancellationPolicies =
    cancellationPolicies + additionalCancellationPolicies

  return (
    updatedCancellationPolicies
      .split(/\.(?!\d)(?=\s|[A-Z])/g)
      .map(text => text.trim()) ?? []
  )
}

const constructCheckInInstructions = (
  instructions: HotelCheckInInstruction[]
): string[] => {
  const values =
    instructions?.map(instruction => {
      if (typeof instruction === 'string') {
        return instruction
      } else {
        return ''
      }
    }) ?? []
  return constructCleanItems(values) ?? []
}

const constructSpecialCheckInInstructions = (
  instructions: HotelSpecialCheckInInstruction[]
): string[] => {
  const values =
    instructions?.map(instruction => {
      if (typeof instruction === 'string') {
        return instruction
      } else {
        return ''
      }
    }) ?? []
  return constructCleanItems(values) ?? []
}

const constructFees = (fees: LodgingFee[]): string[] =>
  constructCleanItems(
    fees
      ?.filter(({ text }) => !!text)
      ?.map(({ text, type }) => `${type} - ${text}`)
  ) ?? []

const constructPolicies = (
  policies: LodgingContentPolicy[]
): LodgingContentPolicy[] => {
  if (isEmpty(policies))
    return [
      {
        text: 'Hotel policies not specified.',
      },
    ]

  return policies
}

const constructRatePolicies = ({
  policies,
  ratePolicies,
}: {
  policies: LodgingContentPolicy[]
  ratePolicies: HotelRatePolicies
}): string[] => {
  const selector = document.createElement('textarea')

  return (
    ratePolicies
      // @ts-ignore
      ?.filter(({ description }) =>
        policies?.some(({ text }) => description !== text)
      )
      ?.map(({ description }) => {
        // TODO: remove after backend removes html entities from room level policies.
        const updatedDescription = description.replace(/&amp;/g, '&')
        selector.innerHTML = updatedDescription

        return constructCleanItem(selector.value)
      })
  )
}

export type {
  HotelCancellationPolicies,
  HotelCancellationShortText,
  HotelCheckIn,
  HotelCheckInInstruction,
  HotelSpecialCheckInInstruction,
  HotelPolicy,
}
export {
  constructCancellationPolicies,
  constructCheckInInstructions,
  constructCleanItem,
  constructSpecialCheckInInstructions,
  constructFees,
  constructPolicies,
  constructRatePolicies,
}
