import classNames from 'classnames'

const getLogoUrl = (variant: TravelpassLogoProps['variant']): string => {
  if (variant === 'primary')
    return 'https://static.travelpass.com/assets/brands/travelpass/logo-primary.webp'

  if (variant === 'beach')
    return 'https://static.travelpass.com/assets/brands/travelpass/logo-beach.webp'

  if (variant === 'beach-valley')
    return 'https://static.travelpass.com/assets/brands/travelpass/logo-beach-valley.webp'

  if (variant === 'symbol-valley')
    return 'https://static.travelpass.com/assets/brands/travelpass/logo-symbol-valley.webp'
}

interface TravelpassLogoProps {
  className?: string
  variant?: 'beach' | 'beach-valley' | 'primary' | 'symbol-valley'
}

export const TravelpassLogo = ({
  className = '',
  variant = 'primary',
}: TravelpassLogoProps) => (
  <img
    alt='Travelpass logo'
    className={classNames('h-auto max-w-full', className)}
    fetchPriority='high'
    src={getLogoUrl(variant)}
  />
)
