import type { ComponentPropsWithoutRef, ReactElement } from 'react'
import { useState } from 'react'
import { EmptyState } from '@travelpass/design-system'
import classNames from 'classnames'

interface GuideImageProps extends ComponentPropsWithoutRef<'img'> {
  emptyState?: ReactElement
}

export const GuideImage = ({
  alt,
  className,
  emptyState,
  loading = 'lazy',
  onError,
  onLoad,
  ...props
}: GuideImageProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)

  if (hasError || !props.src)
    return (
      <div
        className={classNames(
          className,
          'bg-grey-400 of-hidden flex h-full w-full flex-col items-center justify-center'
        )}
        data-testid='GuideImage-empty-state'
      >
        {emptyState || (
          <div className='w-50'>
            <EmptyState name='noImages' />
          </div>
        )}
      </div>
    )

  return (
    <div
      className={classNames(className, 'bg-grey-400 of-hidden h-full w-full', {
        'animate-pulse': isLoading,
      })}
    >
      <img
        style={{ overflowClipMargin: 'unset' }}
        {...props}
        alt={alt}
        className={classNames('flex h-full w-full object-cover', {
          'opacity-0': isLoading,
          'opacity-100': !isLoading,
        })}
        loading={loading}
        onError={event => {
          setHasError(true)
          onError?.(event)
        }}
        onLoad={event => {
          setIsLoading(false)
          onLoad?.(event)
        }}
      />
    </div>
  )
}
