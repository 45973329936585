import isEmpty from 'lodash.isempty'
import { HotelPriceSummaryRow } from '../HotelPriceSummaryRow'
import type { HotelPriceSummaryProps } from '../hotelPriceSummaryConstants'
import {
  constructDueLater,
  constructDueNow,
  constructFees,
  constructTotal,
} from '../hotelPriceSummaryUtils'

interface HotelPriceSummaryPriceProps extends Partial<HotelPriceSummaryProps> {}

const HotelPriceSummaryPrice = ({
  allInTotal,
  additionalFees,
  dueLater,
  dueNow,
  showLegal = false,
}: HotelPriceSummaryPriceProps) => {
  const constructedFees = constructFees(additionalFees)
  const constructedDueLater = constructDueLater(constructedFees, dueLater)
  const constructedDueNow = constructDueNow(dueNow)
  const constructedTotalPrice = constructTotal(
    constructedDueLater,
    constructedDueNow
  )

  const price = allInTotal ? allInTotal : constructedTotalPrice

  const isDueLater = dueLater !== '0.00' && dueLater
  const displayDueAtHotel = !!isDueLater || !isEmpty(constructedFees)

  return (
    <div className='flex flex-col gap-6'>
      <HotelPriceSummaryRow title='Total' value={price} variant='title' />
      {isDueLater && showLegal && (
        <p className='c-grey-800 type-body-2'>
          Please note, you are booking a{' '}
          <q>
            <strong>Reserve Now, Pay Later Room.</strong>
          </q>{' '}
          You will be charged by the hotel at or shortly before your designated
          check-in time
        </p>
      )}
      {displayDueAtHotel && (
        <div className='flex flex-col gap-2'>
          <HotelPriceSummaryRow
            title='Pay now'
            value={constructedDueNow}
            variant='subtitle'
          />
          <HotelPriceSummaryRow
            title='Pay at property'
            value={constructedDueLater}
            variant='subtitle'
          />
        </div>
      )}
    </div>
  )
}

export { HotelPriceSummaryPrice }
export type { HotelPriceSummaryPriceProps }
