import type { Dayjs } from 'dayjs'
import type {
  FilterBy,
  CustomerReviewScore,
  SearchHotelsArgs,
  StarRating,
  PriceRange,
} from 'src/__generated__/graphql'
import { SortBy } from 'src/__generated__/graphql'
import {
  initialAdultGuests,
  initialArrivalDate,
  initialDepartureDate,
  initialKidGuests,
  initialGeocoderLatitude,
  initialGeocoderLongitude,
  initialGeocoderPlaceName,
} from './user'

const initialMaxScore: CustomerReviewScore['maxScore'] = '10.0'
const initialMaxStars: StarRating['maxStars'] = '5.0'
const initialMinScore: CustomerReviewScore['minScore'] = '1.0'
const initialMinStars: StarRating['minStars'] = '1.0'
const initialRadius: SearchHotelsArgs['radius'] = 20
const initialSize: SearchHotelsArgs['size'] = 72
const initialSort: SortBy = SortBy.Recommended

const initialFilters: FilterBy = {
  customerReviewScore: {
    minScore: initialMinScore,
    maxScore: initialMaxScore,
  },
  brands: [],
  includeAirportShuttle: false,
  includeAccessibility: false,
  includeBreakfast: false,
  includeFitnessCenter: false,
  includeHotTub: false,
  includeNonSmoking: false,
  includeParking: false,
  includePetFriendly: false,
  includePool: false,
  includeSpaServices: false,
  includeWifi: false,
  priceRange: {
    minPrice: 0,
    maxPrice: 1000,
  },
  starRating: {
    minStars: initialMinStars,
    maxStars: initialMaxStars,
  },
}

interface allSearchParamsDecoded extends FilterBy {
  adults: number
  arrival: Dayjs
  departure: Dayjs
  kids: number
  latitude: number
  location: string
  longitude: number
  mapBounds?: string
  mapExpanded?: boolean
  mapZoom?: number | boolean
  placeCountry?: string
  placeId?: string
  placeShortName?: string
  placeType?: string
  radius?: string
  sort: SortBy
}

const defaultHotelResultsParams: allSearchParamsDecoded = {
  ...initialFilters,
  adults: initialAdultGuests,
  arrival: initialArrivalDate,
  departure: initialDepartureDate,
  kids: initialKidGuests,
  latitude: initialGeocoderLatitude,
  location: initialGeocoderPlaceName,
  longitude: initialGeocoderLongitude,
  mapBounds: '',
  mapExpanded: false,
  mapZoom: false,
  placeCountry: '',
  placeId: '',
  placeShortName: '',
  placeType: '',
  radius: '',
  sort: initialSort,
}

interface allSearchParamsEncoded {
  [key: string]: string
  adults: string
  arrival: string
  brands: string
  customerReviewScore: string
  departure: string
  includeAirportShuttle: string
  includeAccessibility: string
  includeBreakfast: string
  includeFitnessCenter: string
  includeHotTub: string
  includeNonSmoking: string
  includeParking: string
  includePetFriendly: string
  includePool: string
  includeSpaServices: string
  includeWifi: string
  kids: string
  latitude: string
  location: string
  longitude: string
  mapBounds: string
  mapExpanded: string
  mapZoom: string
  placeCountry: string
  placeId: string
  placeShortName: string
  placeType: string
  priceRange: string
  radius: string
  sort: SortBy
  starRating: string
}

const defaultHotelResultsParamsStrs: allSearchParamsEncoded = {
  adults: initialAdultGuests.toString(),
  arrival: initialArrivalDate.format('YYYY-MM-DD'),
  brands: JSON.stringify([]),
  customerReviewScore: JSON.stringify(initialFilters.customerReviewScore),
  departure: initialDepartureDate.format('YYYY-MM-DD'),
  includeAirportShuttle: 'false',
  includeAccessibility: 'false',
  includeBreakfast: 'false',
  includeFitnessCenter: 'false',
  includeHotTub: 'false',
  includeNonSmoking: 'false',
  includeParking: 'false',
  includePetFriendly: 'false',
  includePool: 'false',
  includeSpaServices: 'false',
  includeWifi: 'false',
  kids: initialKidGuests.toString(),
  latitude: initialGeocoderLatitude.toString(),
  location: initialGeocoderPlaceName,
  longitude: initialGeocoderLongitude.toString(),
  mapBounds: 'false',
  mapExpanded: 'false',
  mapZoom: 'false',
  maxPrice: initialFilters.priceRange.maxPrice.toString(),
  maxScore: initialFilters.customerReviewScore.maxScore.toString(),
  maxStars: initialFilters.starRating.maxStars.toString(),
  minPrice: initialFilters.priceRange.minPrice.toString(),
  minScore: initialFilters.customerReviewScore.minScore.toString(),
  minStars: initialFilters.starRating.minStars.toString(),
  placeCountry: 'false',
  placeId: 'false',
  placeShortName: 'false',
  placeType: 'false',
  priceRange: JSON.stringify(initialFilters.priceRange),
  radius: defaultHotelResultsParams.radius,
  sort: initialSort,
  starRating: JSON.stringify(initialFilters.starRating),
}

export type { allSearchParamsDecoded }
export {
  initialMaxScore,
  initialMaxStars,
  initialMinScore,
  initialMinStars,
  initialRadius,
  initialSize,
  initialSort,
  initialFilters,
  defaultHotelResultsParams,
  defaultHotelResultsParamsStrs,
}
