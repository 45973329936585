import type { RefObject } from 'react'
import { forwardRef, useState } from 'react'
import { Divider, Link, Modal } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import type { LodgingFee } from 'src/__generated__/graphql'
import {
  constructFees as constructHotelFees,
  getTotalNights,
  getTotalRooms,
} from 'src/utils'
import { HotelPriceSummaryPrice } from './HotelPriceSummaryPrice'
import { HotelPriceSummaryRow } from './HotelPriceSummaryRow'
import { HotelPriceSummaryTaxes } from './HotelPriceSummaryTaxes'
import type { HotelPriceSummaryProps } from './hotelPriceSummaryConstants'
import { constructFees } from './hotelPriceSummaryUtils'

export const HotelPriceSummary = forwardRef(function HotelPriceSummary(
  {
    allInTotal,
    additionalFees,
    arrival,
    departure,
    dueLater,
    dueNow,
    hotelFees,
    provider,
    showInternationalDisclaimer = false,
    showLegal = false,
    subtotal,
    surcharges,
    surchargeTotal,
    rooms,
  }: HotelPriceSummaryProps,
  ref: RefObject<HTMLHeadingElement>
) {
  const [isBookingOtherFeesModalOpen, setIsBookingOtherFeesModalOpen] =
    useState(false)
  const constructedFees = constructFees(additionalFees)
  const constructedHotelFees = constructHotelFees(
    hotelFees?.filter(
      (fee: LodgingFee | null | undefined): fee is LodgingFee =>
        fee !== null && fee !== undefined
    ) ?? []
  )
  const showOtherFees =
    !isEmpty(hotelFees) && (provider === 'getaroom' || provider === 'web_beds')

  const constructedOccupancyText = () =>
    `${getTotalRooms({
      isLowerCase: true,
      rooms,
    })} x ${getTotalNights({
      arrival,
      departure,
      isLowerCase: true,
    })}:`

  return (
    <div className='rebrand'>
      {isBookingOtherFeesModalOpen && (
        <Modal
          size='medium'
          title='Additional Fees'
          onDismiss={() => setIsBookingOtherFeesModalOpen(false)}
        >
          <div className='flex flex-col gap-2'>
            {constructedHotelFees.map((fee, index) => (
              <p key={index} className='c-grey-800'>
                {fee}
              </p>
            ))}
          </div>
        </Modal>
      )}
      <div className='flex flex-col gap-6' data-testid='HotelPriceSummary'>
        <h3 className='text-h6 scroll-mt-6' ref={ref}>
          Price Summary:
        </h3>
        <div className='flex flex-col gap-2'>
          <HotelPriceSummaryRow
            title={constructedOccupancyText()}
            value={subtotal}
          />
          <HotelPriceSummaryTaxes
            surchargeTotal={surchargeTotal}
            surcharges={surcharges}
          />
          {constructedFees?.map(({ amount, type }) => (
            <HotelPriceSummaryRow
              key={type}
              title={`${type ?? ''} (pay at property):`}
              value={amount?.amount ?? ''}
            />
          ))}
        </div>
      </div>
      <Divider className='mb-8 mt-4' />
      {showOtherFees && (
        <div className='flex flex-row items-center justify-between gap-2 pb-10'>
          <p className='c-grey-800 type-body-2'>
            *Additional fees may be due later at the hotel
          </p>
          <div className='min-w-fit'>
            <Link
              label='More Info'
              onClick={() => setIsBookingOtherFeesModalOpen(true)}
            />
          </div>
        </div>
      )}
      {showInternationalDisclaimer && (
        <div className='flex flex-row items-center justify-between gap-2 pb-10'>
          <p className='c-grey-800 type-body-2'>
            *Rates are based off of the current exchange rate and fees charged
            at the time of checkin will be collected in the hotel&apos;s local
            currency.
          </p>
        </div>
      )}
      <HotelPriceSummaryPrice
        additionalFees={additionalFees}
        allInTotal={allInTotal}
        dueLater={dueLater}
        dueNow={dueNow}
        showLegal={showLegal}
      />
    </div>
  )
})
