import {
  ExperienceCancellationType,
  RefundType,
} from 'src/__generated__/graphql'
import { constructAddress, getPrice } from 'src/utils'
import { getHoursWithMinutes } from 'src/utils/timeUtils'
import { defaultProductData } from './productCardConstants'
import type { ProductCardData } from '../types'

interface constructDisplayPriceArgs {
  afterText?: string
  beforeText?: string
  price?: ProductCardData['primaryPrice']
  condition?: boolean
  defaultValue?: string
}

const constructDisplayPrice = ({
  afterText = '',
  beforeText = '',
  price,
  condition = true,
  defaultValue = '',
}: constructDisplayPriceArgs) => {
  if (!condition || isNaN(parseInt(price))) {
    return defaultValue
  }
  const formatedPrice = getPrice(price)

  return `${beforeText}${formatedPrice}${afterText}`
}

const constructDisplayName = (name?: ProductCardData['name']) => {
  if (!name) return defaultProductData.name

  return name
}

const constructDisplayReviewScore = (
  constructDisplayReviewScore?: Partial<ProductCardData>
) => {
  const { customerReviewScore, customerReviewScoreScale } =
    constructDisplayReviewScore ?? {}
  if (!customerReviewScore || customerReviewScore === 0) return ''

  const reviewScoreScale = customerReviewScoreScale ?? 10

  return `${customerReviewScore.toFixed(1)}/${reviewScoreScale}`
}

const constructDisplayTotalReviews = (
  totalCustomerReviews?: ProductCardData['totalCustomerReviews']
) => {
  let totalReviews = '0'

  if (totalCustomerReviews) {
    if (totalCustomerReviews < 10000) {
      totalReviews = totalCustomerReviews.toLocaleString()
    } else {
      const reduceThou = totalCustomerReviews / 1000
      const thouToK = reduceThou.toFixed(1)
      totalReviews = `${thouToK}K`
    }
  }

  return `(${totalReviews} reviews)`
}

const constructDisplaySubtitle = ({
  address,
  durationMinutes,
  duration,
}: Partial<ProductCardData>) => {
  const { city, stateAbbreviation, streetAddress } = address ?? {}

  if (city && stateAbbreviation) {
    return constructAddress({
      addressFirstLine: streetAddress,
      city,
      hideCountry: true,
      hideZipcode: true,
      state: stateAbbreviation,
    })
  } else if (durationMinutes && durationMinutes !== 0) {
    return getHoursWithMinutes(durationMinutes)
  } else if (duration) {
    return duration
  }
  return ''
}

interface constructDisplayBottomTextArgs extends ProductCardData {
  condition?: boolean
}

const constructDisplayBottomText = ({
  condition,
  displayPriceUnit,
  priceUnit,
  secondaryPrice,
}: constructDisplayBottomTextArgs) => {
  if (secondaryPrice) {
    return constructDisplayPrice({
      afterText: ' subtotal',
      condition,
      price: secondaryPrice,
    })
  } else if (displayPriceUnit) {
    const unit = priceUnit
      ? priceUnit.toLocaleLowerCase()
      : defaultProductData.priceUnit
    return `per ${unit}`
  }

  return ''
}

interface constructDisplayCancellationArgs extends ProductCardData {
  isProductAvailable?: boolean
}
const constructDisplayCancellation = ({
  cancellationPolicy,
  isProductAvailable,
}: constructDisplayCancellationArgs) => {
  if (isProductAvailable) {
    if (
      cancellationPolicy &&
      cancellationPolicy !== ExperienceCancellationType.None &&
      cancellationPolicy !== RefundType.None
    )
      return cancellationPolicy
    return null
  }
  return 'SOLD_OUT'
}
export {
  constructDisplayBottomText,
  constructDisplayCancellation,
  constructDisplayPrice,
  constructDisplayReviewScore,
  constructDisplaySubtitle,
  constructDisplayTotalReviews,
}
