import { TENANT_ID_DESKTOP, TENANT_ID_MOBILE } from 'src/constants'
import { Environment } from 'src/utils'

const { PROD, STG, INT } = Environment

export const getTenantId = (isMobileOrTablet: boolean) => {
  return isMobileOrTablet ? TENANT_ID_MOBILE : TENANT_ID_DESKTOP
}

export const getEnvUrlPiece = (env: string) => (env === PROD ? '' : `${env}.`)

export const getBucketEnvUrlPiece = (env: string) => {
  switch (env) {
    case PROD:
      return ''
    case STG:
      return 'stg.'
    case INT:
      return 'int.'
  }
}
