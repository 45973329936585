import { Button } from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import { PageLayoutContainer } from '../PageLayoutContainer'

export const PageNotFound = () => {
  const navigate = useNavigate()

  return (
    <PageLayoutContainer>
      <div className='min-h-100vh m-0 flex flex-col-reverse items-center justify-center text-center md:flex-row md:px-10 md:text-left'>
        <div className='space-y-4'>
          <div className='type-h1'>Oops!</div>
          <div className='type-h1'>Page Not Found</div>
          <div className='type-h5'>
            This page doesn’t exist or was removed! We suggest you go back to
            the home page.
          </div>
          <Button label='Back to Home' onClick={() => navigate('/')} />
        </div>
        <img
          alt='error'
          className='w-50% h-50%'
          src='https://static.travelpass.com/assets/404.webp'
        />
      </div>
    </PageLayoutContainer>
  )
}
