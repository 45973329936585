import { AutocompleteOption, Icon } from '@travelpass/design-system'
import type { Suggestion } from 'use-places-autocomplete'

const GeocoderOptionIconByType = ({ types }: { types: string[] }) => {
  if (types.includes('airport')) {
    return (
      <span className='c-grey-800'>
        <Icon name='airplaneModeActive' />
      </span>
    )
  }
  if (types.includes('lodging')) {
    return (
      <span className='c-grey-800'>
        <Icon name='business' />
      </span>
    )
  }
  return (
    <span className='c-new-forest'>
      <Icon name='place' />
    </span>
  )
}

export const GeocoderOption = ({
  option,
  searchTerm,
}: {
  option: Suggestion
  searchTerm?: string
}) => {
  const { main_text, secondary_text } = option?.structured_formatting ?? {}
  const label = `${main_text} ${secondary_text ?? ''}`

  // Function to highlight the search term in the text
  const getHighlightedText = (text: string, highlight: string) => {
    // Escape special characters for regex
    const escapedHighlight = highlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    const parts = text.split(new RegExp(`(${escapedHighlight})`, 'gi')) // Split text by the search term
    return (
      <>
        {parts.map((part, index) =>
          part.toLowerCase() === highlight.toLowerCase() ? (
            <span key={index} className='font-bold'>
              {part}
            </span>
          ) : (
            <span key={index}>{part}</span>
          )
        )}
      </>
    )
  }

  return (
    <AutocompleteOption
      className='type-body-1 b-none c-black hover:bg-grey-100 focus:bg-grey-100 aria-selected:bg-warm-grey flex w-full cursor-pointer flex-row items-center gap-2 bg-white px-4 py-1.5 text-left outline-none'
      value={option}
    >
      <GeocoderOptionIconByType types={option.types} />
      <span className='line-clamp-2'>
        {searchTerm ? getHighlightedText(label, searchTerm) : label}
      </span>
    </AutocompleteOption>
  )
}
